import React from "react";
import Rating from "./Rating";
import { Link } from "react-router-dom";

export default function Product(props) {
  const { product } = props;
  return (
    // <div className="row">
    <div key={product._id} className="col-lg-6 col-md-6 m-b30">
      <div className="wt-box wt-product-box block-shadow  overflow-hide">
        <div className="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
          <Link to={`/product/${product._id}`}>
            <img className="medium" src={product.image} alt={product.name} />
          </Link>
        </div>

        <div className="wt-info  text-center">
          <div className="p-a10 bg-white">
            <h3 className="wt-title m-b0">
              <Link to={`/product/${product._id}`}>
                <span style={{ color: "gray" }}>{product.name}</span>
              </Link>
            </h3>

            <span className="price">
              <ins>
                <span>
                  <span className="Price-currencySymbol">tk. </span>
                  {product.price}
                </span>
              </ins>
            </span>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}



// <div key={product._id} className="col-lg-6 col-md-6 m-b30">
//       <div className="wt-box wt-product-box block-shadow  overflow-hide">
//         <div className="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
//           <Link to={`/product/${product._id}`}>
//             <img className="medium" src={product.image} alt={product.name} />
//           </Link>
//         </div>

//         <div className="wt-info  text-center">
//           <div className="p-a10 bg-white">
//             <h3 className="wt-title m-b0">
//               <Link to={`/product/${product._id}`}>
//                 <span style={{ color: "gray" }}>{product.name}</span>
//               </Link>
//             </h3>

//             <span className="price">
//               <ins>
//                 <span>
//                   <span className="Price-currencySymbol">tk. </span>
//                   {product.price}
//                 </span>
//               </ins>
//             </span>
//           </div>
//           {/* </div> */}
//         </div>
//       </div>
//     </div>