import React from "react";
import { NavLink } from "react-router-dom";
import ProductData from "../../data/ProductData";

// const products = [
//     {
//         image: require('./../../images/products/pic-1.jpg'),
//         title: 'One Mechanical Tool',
//         price: '449.00'
//     },
//     {
//         image: require('./../../images/products/pic-2.jpg'),
//         title: 'Two Mechanical Tool',
//         price: '599.00'
//     },
//     {
//         image: require('./../../images/products/pic-3.jpg'),
//         title: 'Three Mechanical Tool',
//         price: '499.00'
//     }
// ]

class ShopProducts extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          {ProductData.Products.map((item, index) => (
            <div key={index} className="col-lg-6 col-md-6 m-b30">
              <div className="wt-box wt-product-box block-shadow  overflow-hide">
                <div className="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
                  <img src={item.image.default} alt="" />
                </div>
                <div className="wt-info text-center">
                  <div className="p-a20 bg-white">
                    <h3 className="wt-title">
                      {/* <NavLink to={"/shop-detail"}>{item.title}</NavLink> */}
                      <NavLink to={"/#"}>{item.title}</NavLink>
                    </h3>
                    <span className="price">
                      <ins>
                        <span>
                          <span className="Price-currencySymbol"> </span>
                          {item.price}
                        </span>
                      </ins>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default ShopProducts;
