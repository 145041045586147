import React from 'react';
import ServicesSidebar from './../Segments/ServicesSidebar';

class ServiceDetails1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                            <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">

                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Agricultural edit</h2>
                                        </div>
                                        {/* TITLE END */}

                                        <div className="wt-box services-etc m-b30">
                                            <p>Engineering is concerned with the development, improvement, and implementation of inte grated systems of people, money, knowledge, information, equipment, energy, materials.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic9.jpg').default} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>Concerned with the development, improvement, and implementation of integrated system of people, money, knowledge, information, equipment, energy, materials, analysis and syn thesis, a.</p>
                                                <blockquote className="m-a0 bg-gray p-a30">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>Raising a heavy fur muff that covered the whole of her lower arm towards the viewer gregor then turned to look out
                    </p>
                                                    <div className="p-t15 text-uppercase">
                                                        <strong>William Jomurray</strong>
                                                        <span>Envato Author</span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>System Benefits</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, . Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li>Pitifully thin compared with the size of the rest of him.</li>
                                                        <li>Raising a heavy muff that covered the whole. muff that covered the whole of her lower arm consectetur.</li>
                                                        <li>Muff that covered the whole of her lower arm consectetur adipisicing elit</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg').default} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised wordtions of passages.</div>
                                                    </div>
                                                </div>
                                                
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design loype setting industry. Lorem Ipsum an unknown printer took.</div>
                                                    </div>
                                                </div>


                                                
                                                

                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ServiceDetails1;